.contract-overview-modal{
    padding: 0;
    margin: 0;
    width: 100%;
}

.contract-overview-modal-dialog{
    padding: 0;
    width: 100%!important;
    max-width: 90%!important;
    height: 95vh;
}

.contract-overview-modal-backdrop{
    background-color: black;
    opacity: .9;
}


.contract-overview-modal-body{
    padding: 0;
    margin: 0;
    max-height: 100%;
    height: 100%
 }

 .overview-modal-left{
    background-color: #ffffff;
    max-height: 100%;
    overflow: auto;
    padding: 30px 20px;
    height: calc(100vh - 300px);
    .section{
        margin-bottom: 10px;
        padding: 10px;
        .section-title{
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .custom-field{
            .custom-field-title{
                padding: 5px;
                width: auto;
            }
            .custom-field-value{
                padding: 5px;
            }
        }
    }
 }
 .overview-modal-right{
    background-color: #f5f5f9;
    max-height: 100%;
    height: calc(100vh - 300px);
    overflow: auto;
    padding: 0;
 }
 .DraftEditor-root{
    padding: 10px;
    margin: 0;
    margin-top: 10px;
    height: 100%;
    min-height: 600px;
    overflow: auto;
    border: 1px solid #e0e0e0;
 }
 span[data-testid="mentionText"] {
     /* Button Style */
        background-color: #f5f5f9;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        color: #000;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 0;
        padding: 6px 12px;
        white-space: nowrap;
        word-wrap: break-word;
        /* Button Style */

 }

 .contract-overview-topbar{
    box-sizing: border-box;
    height: 80px;
    background: #f5f5f9;
    border-bottom: 1px solid #e0e0e0;
    .contract-overview-topbar-right{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        .status-select{
            width: 150px;
            min-width: 150px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .react-select{
                width: 100%;
            }
        }
        .assigned-users{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            .user-list{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                flex-direction: row-reverse;
                .user{
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    margin-left: -10px;
                    z-index: 9;
                }
                .user-add{
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    margin-left: -10px;
                    z-index: 8;
                }
            }
            .locked{
                margin-right: 10px;
            }
        }
    }
 }

 .co-info-boxes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px;
    box-sizing: border-box;
    height: 80px;
    overflow: hidden;
    .co-info-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
 }

.overview-title-input, .overview-about-input{
    width: 100%;
    border: 0 solid #e0e0e0;
    outline: none!important;
    box-sizing: border-box;
    padding: 10px;
    &:focus{
        border: 1px solid #e0e0e0;
    }
    &:hover{
        border: 1px solid #e0e0e0;
    }
}

.overview-title-input{
    font-size: 32px;
    line-height: 42px;
    font-weight: 400;
    margin-bottom: 0;
    margin-bottom: 10px;
    resize: none;
    
}



.flat-scroll {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;

    /* IE and Edge /
              scrollbar-width: none;  / Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.no-more {
    color: #ccc;
}

.overview-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.breadcrumb-overview{
    background: white;
    padding:5px 10px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    
    .breadcrumb-item{
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        a{
            color: #303030;
            &:hover {
                    color: #000;
                }
        }
       
    }
}


.accordion{
    *{

            box-shadow: none;
            border: none;
    }
    .accordion-item{
        border-radius: 4px;
            overflow: hidden;
            margin-bottom: 10px;
        .accordion-header{
            border-radius: 4px;
            margin: 0;
            overflow: hidden;
            button{
                &::after{
                    width: .75rem;
                    height: .75rem;
                    background-size: .75rem;
                }
            }
        }
    }
}