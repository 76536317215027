.overview-files-table {

    z-index: 9;

    .active {
        background-color: black;
        color: white;
    }

    ul {
        list-style: none !important;
    }

    thead {
        tr {
            width: 100%;

            th {
                max-width: 420px;
                text-align: center;
                height: 70px;
            }

            .sortable {
                min-width: 160px;

                &:hover {
                    background-color: #050027;
                    color: white;
                }
            }

            .sorted {
                background-color: #050027;
                color: white;
                position: relative;

                svg {
                    //border: 3px solid yellow;
                    position: absolute;
                    top: 27.5px;
                    right: 20px !important;
                }
            }

        }
    }

    tbody {
        z-index: 9;
        margin-bottom: 100px;

        .no-found-data {
            height: 70px !important;
        }

        tr {
            height: 50px !important;

            .sorted {
                background-color: #050027;
            }

            .source-logo {
                img {
                    object-fit: contain;
                }
            }

            .filename {
                min-width: 150px;
                max-width: 300px;
                color: #F918F0;
                font-weight: bold;
                height: 100%;

                a {
                    color: #F918F0;
                    font-weight: bold;
                    height: 100%;
                }
            }

            .createdDate {
                max-width: 200px;
            }

            .user {
                min-width: 150px;
                font-weight: bold;
            }

            .contract {
                max-width: 250px;
                min-width: 250px
            }

            .tags-td {
                position: relative;
                min-width: 250px;

                input {
                    width: 80%;
                }

                small {
                    position: absolute;
                    bottom: 0px;
                    font-size: 10px;
                    font-weight: bold;
                }
            }

            .size {
                max-width: 80px;
            }

            .type {
                max-width: 140px;
            }

            .action {
                min-width: 160px;
            }

            td {
                min-width: 80px;
                max-width: 380px;
                text-align: center;


                .tags {

                    .chip {
                        text-overflow: ellipsis;
                        max-width: 120px;
                        display: inline-block;
                        margin-right: 5px;
                        padding: 5px 10px;
                        min-width: 55px;
                        text-align: center;
                        margin-top: 5px;
                        border-radius: 1rem;
                    }

                    .gray {
                        color: black;
                        background-color: #ffbe0b;
                    }

                    .green {
                        background-color: #43d39e;
                        color: black;
                        cursor: pointer;
                    }

                    .edit {
                        background-color: #43d39e;
                        color: white;
                    }
                }

            }
        }
    }

    tfoot {
        height: 45px !important;

        .page-input {
            min-width: 150px;
        }

        td {
            width: 100% !important;

            .tfoot-container {
                display: flex;
                width: 100%;
                min-width: 100%;

                div.tfoot-item {
                    width: 50%;
                    height: 50px;
                }

                .absolute-paginate {
                    position: absolute;
                    bottom: 60px;
                    width: 60px;
                    right: 80px;
                }
            }
        }
    }
}