.select-contracts-type {
    #react-select-3-listbox {
        .css-9gakcf-option {
            background-color: #050027 !important;
            color: white !important;
        }

        .css-1n7v3ny-option {
            background-color: #050027 !important;
            color: white !important;
        }
    }
}