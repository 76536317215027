// Styles

// Main styles

@import './bootstrap.scss';
@import './app.scss';

// Shreyu Icons
@import './icons.scss';

//Disable Chrome two fingers back/forward swipe
html,
body {
  overscroll-behavior-x: none;
}

.table-responsive-md, .table-responsive-lg, .table-responsive-sm, .table-responsive-xs {
  overflow-x: auto;
  max-width: 100%;
}

.bg-logo-color{
  background-color: #0144E4!important;
  outline: #0144E4!important;
  border: #0144E4!important;
}

.text-logo-color{
  color: #0144E4 !important;
}

.btn-logo-color {
  background-color: #0144E4 !important;
  outline: #0144E4 !important;
  border: #0144E4 !important;
  transition: all 300ms linear;
  font-weight: 600;
  &:hover {
    background-color: #002c93 !important;
  }
}

.modal-shadow-border {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
