.file-version-table {
    thead {
        width: 100%;
        text-align: center;
    }

    tbody {
        align-items: center;
        text-align: center;


        .filename {
            min-width: 150px;
            max-width: 300px;
            color: #F918F0;
            font-weight: bold;
            word-break: break-all;
            height: 100%;

            a {
                color: #F918F0;
                font-weight: bold;
                height: 100%;
            }
        }
    }
}